import React from 'react';

const GradientMain = () => (
  <div id="background-gradient">
    <span />
    <span />
    <span />
    <span />
    <span />
  </div>
);


export default GradientMain;
